






































































import useAbstract from "@/use/abstract";
import useMisc from "@/use/misc";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const { getLanguageVersion } = useMisc({ root });
    const { getTypeName } = useAbstract({ root });

    const state = reactive({
      loading: false,
      loaded: false,
      topics: [],
    });
    const history = computed(() => {
      if (props.item && props.item.changeLog && props.item.changeLog.length) {
        const changeLog = props.item.changeLog.sort((a: any, b: any) => {
          const firstDate = new Date(a.date).getTime();
          const secondDate = new Date(b.date).getTime();
          return secondDate - firstDate;
        });
        return changeLog;
      } else return [];
    });

    const fetchTopics = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/session-topic`)
        .then(({ data: { sessionTopic } }) => {
          state.topics = sessionTopic.filter((el: any) => el.isActive);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.topics = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchTopics);

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };

    return { getLanguageVersion, getTypeName, state, history, getFile };
  },
});
